import React from 'react';
import '../Header/header.css'
import './principal.css'
import img4 from '../assets/images/gemco.png'
import img8 from '../assets/images/mettler-toledo.jpg';
import img5 from '../assets/images/hobre.png';
import img7 from '../assets/images/hobre.png';
import img6 from '../assets/images/jmcanty.jpg';
import img1 from '../assets/images/monicon.png';
import img9 from '../assets/images/monicon.png'; 
import ig1 from '../assets/images/pic10.png'
import ig2 from '../assets/images/pic11.png'
import ig3 from '../assets/images/pic9.png';

const products = [
    {
        logo: img4,
        title: 'GEMCO International B.V (Exclusive Agency)',
        description: 'Specialized in design, engineering & construction of industrial workshops',
    },
    {
        logo: img8,
        title: 'Mettler Toledo AG Process Analytics',
        description: 'Process Analytics, Measurement Solutions for Industrial Applications',
    },
    {
        logo: img5,
        title: 'Rose Systemtechnik',
        description: 'Junction Boxes & Custom made Industrial Enclosures',
    },
    {
        logo: img7,
        title: 'Servomex Group Limited',
        description: 'Process/Oxygen, Hydrocarbon, CO, CO2, NOX Analysers for oil & gas industries',
    },
    {
        logo: img6,
        title: 'Shaw Moisture Meters',
        description: 'Portable & online Moisture Analysers',
    },
    {
        logo: img1,
        title: 'Monicon Technology',
        description: 'Gas Detection Systems',
    },
    {
        logo: img9,
        title: 'Orga B.V',
        description: "Operational safety underpins the aviation and offshore industries' licenses to operate. Up-to-date aids to navigation and obstruction lighting that meet relevant operational needs and safety regulations are vitally important to maintain these licenses."
    },
];

const ProductPage = () => {
    return (
        <div id="content-container" className="container">
            <div className="content-module">
                <div className="left-side-block">
                    <div className="block-content company-images">
                        <img src={ig1} alt='image'/>
                    </div>
                    <div className="block-content company-images">
                        <img src={ig2} alt='image'/>
                    </div>
                    <div className="block-content company-images">
                        <img src={ig3} alt='image'/>
                    </div>
                </div>
                <div className="left-main-block">
                    <h1 className="page-title">Products</h1>
                    {products.map((product, index) => (
                        <div key={index} className="block-content">
                            <div className="logo-box">
                                <img src={product.logo} alt={product.title} />
                            </div>
                            <div className="product-details">
                                <h3 className="inner-title">{product.title}</h3>
                                <p>{product.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="clear"></div>
            </div>
        </div>
    )
}

export default ProductPage;