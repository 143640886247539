import './header.css'
import { Navigate, useNavigate } from 'react-router-dom';
import { useState } from 'react';

const Header = () => {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleNavigation = (path) => {
        navigate(path);
        setIsMenuOpen(false); // Close menu after navigation
    };

    return (
      <>
        <div id="header" className="container">
          <div className="content-module">
            <div className="banner-area"></div>
          </div>
        </div>
        <div id="navigator" className="container">
          <div className="content-module">
            {/* Desktop Navigation */}
            <div className="nav-items-list desktop-nav">
              <a onClick={() => handleNavigation('/')}>Home</a>
              <a onClick={() => handleNavigation('/aboutUs')}>About Us</a>
              <a onClick={() => handleNavigation('/products')}>Products</a>
              <a onClick={() => handleNavigation('/clients')}>Clients</a>
              <a onClick={() => handleNavigation('/services')}>Services</a>
              <a onClick={() => handleNavigation('/contact')}>Contact Us</a>
              <div className="clear"></div>
            </div>
            
            {/* Mobile Navigation */}
            <div className={`hamburger-menu ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <nav className={`mobile-nav ${isMenuOpen ? 'active' : ''}`}>
              <ul className="menu-items">
                <li><a onClick={() => handleNavigation('/')}>Home</a></li>
                <li><a onClick={() => handleNavigation('/aboutUs')}>About Us</a></li>
                <li><a onClick={() => handleNavigation('/products')}>Products</a></li>
                <li><a onClick={() => handleNavigation('/clients')}>Clients</a></li>
                <li><a onClick={() => handleNavigation('/services')}>Services</a></li>
                <li><a onClick={() => handleNavigation('/contact')}>Contact</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    );
};

export default Header;
  