import '../Header/header.css'
import { Navigate, useNavigate } from 'react-router-dom'
const HomeComponent = () => {
    const navigate = useNavigate()
    return (
        <div id="content-container" className="container">
        <div className="content-module">
          <div className="left-main-block">            
            <h1 className="page-title">Welcome to ABEST</h1>
            <p>
              Aber Ahmed Equipment Est., ABEST is established in the year 1995, in Abu Dhabi, UAE. Abest is a technical trading company managed by a team of Qualified Professionals having two decades of experience in Oil/Gas, Marine, and Industrial Sectors of the United Arab Emirates. Many of our Engineers have been trained in Europe/USA by our Principals, and our Workshop/Test Facilities are fully equipped with modern equipment to ensure high standards of service for our Clients. We also locally stock substantial quantities of materials and instruments related to Fire &amp; Gas Detection, Fire Fighting, and Safety.
              <br /><br />
              ABEST has been in business for over a decade. During this period, we have gained the trust and goodwill of various Clients, and our client base continues to grow, not just in the UAE but in neighboring countries as well. Our range of Products and Services has also expanded to meet different market demands. However, our core business continues to be in the fields of Fire and Gas Detection, Process Analyzers, and Fire Fighting Systems.
            </p>
            <a className="readmore-button button" onClick={()=>navigate('/aboutUs')}>
              Read More...
            </a>
            <div className="clear"></div>
          </div>
  
          <div className="right-side-block">
            <div className="block-content">
              <h3 className="content-title">Contact Us</h3>
              <div className="content-data company-address">
                <b>Aber Ahmed Equipment Est. (ABEST)</b> <br />
                Flat No. 708, 7th Floor, Al Ghaith Tower <br />
                P.O. Box: 43596 <br />
                Hamdan Bin Mohammed Street <br />
                Sector: E8, Zone: Al Danah <br />
                Abu Dhabi, U.A.E. <br />
                Tel: +971 2 6766366 <br />
                <b style={{ color: '#C3CEDF' }}>E-mail:</b>{' '}
                <a href="mailto:sales@abest.ae">sales@abest.ae</a>
              </div>
            </div>
  
            <div className="block-content">
              <h3 className="content-title">Our Expertise</h3>
              <div className="content-data" style={{ paddingLeft: '5px' }}>
                <b>Automation Control</b> <br /><br />
                <b>Process Analyzer System</b> <br /><br />
                <b>Analyzer Shelters</b> <br /><br />
                <b>Gas/Oil/Marine Materials</b> <br /><br />
                <b>Gas Chromatography</b> <br /><br />
                <b>C. E. M. S</b> <br /><br />
                <b>Navigation Aid Systems</b> <br /><br />
                <b>Installation/Commissioning</b> <br /><br />
                <b>Calibration/Testing</b> <br /><br />
                <b>Servicing/Repairing</b> <br /><br />
                <b>Specialist Manpower</b>
              </div>
            </div>
          </div>
  
          <div className="clear"></div>
        </div>
      </div>
    )
}

export default HomeComponent
