import React from 'react';
import './client.css';
import img1 from '../assets/images/pic10.png'
import img2 from '../assets/images/pic11.png'
import img3 from '../assets/images/pic9.png';
import img4 from '../assets/images/client-aadc.jpg';
import img10 from '../assets/images/client-adco.png';
import img5 from '../assets/images/client-adgas.png';
import img6 from '../assets/images/client-adma-opco.png';
import img7 from '../assets/images/client-adnoc-distribution.png';
import img9 from '../assets/images/client-etisalat.png';
import img12 from '../assets/images/client-gasco.png';
import img11 from '../assets/images/client-ras-al-khaimah.jpg';
import '../styles/common.css'

const clients = [
    {
        logo: img4,
        title: 'Abu Dhabi Company For Onshore Oil Operations (ADCO)',
    },
    {
        logo: img5,
        title: 'Abu Dhabi Gas Liquefaction Co. Ltd (ADGAS)',
    },
    {
        logo: img6,
        title: 'Al Ain Distribution Company (AADC)',
    },
    {
        logo: img7,
        title: 'Abu Dhabi Marine Operating Company (ADMA-OPCO)',
    },
    {
        logo: img4,
        title: 'Abu Dhabi National Oil Company Distribution (ADNOC)',
    },
    {
        logo: img9,
        title: 'Ruwais Fertilizer Industries (FERTIL)',
    },
    {
        logo: img10,
        title: 'Abu Dhabi Gas Industries Ltd. (GASCO)',
    },
    {
        logo: img11,
        title: 'Abu Dhabi Oil Refining Company (TAKREER)',
    },
    {
        logo: img12,
        title: 'National Drilling Company (NDC)',
    },
];

const ClientComponent = () => {
    return (
        <div id="content-container" className="container">
            <div className="content-module">
                <div className="left-side-block">
                    <div className="block-content company-images">
                        <img src={img1} alt='image'/>
                    </div>
                    <div className="block-content company-images">
                        <img src={img2} alt='image'/>
                    </div>
                    <div className="block-content company-images">
                        <img src={img3} alt='image'/>
                    </div>
                </div>
                <div className="left-main-block">
                    <h1 className="page-title">Our Clients</h1>
                    {clients.map((client, index) => (
                        <div key={index} className="block-content">
                            <div className="logo-box">
                                <img src={client.logo} alt={client.title} />
                            </div>
                            <div className="product-details">
                                <h3 className="inner-title">{client.title}</h3>
                                <p>{client.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="clear"></div>
            </div>
        </div>
    )
}

export default ClientComponent